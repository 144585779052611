<template>
  <div class="confirmed-portal-data" v-if="canView">
    <page-header :title="title" icon="fa fa-home-user" :links="getLinks()">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)" ref="excelMe">
      <div class="sub-menu" style="margin-bottom: 2px;">
        <b-row>
          <b-col cols="4">
            <b-input type="text" v-model="entityFilter" placeholder="Filtrer par nom de famille">
            </b-input>
          </b-col>
          <b-col cols="4" v-if="inscriptions.length && changes.length">
            <b-checkbox id="showActivities" v-model="showActivities" v-if="inscriptions.length">
              Inscriptions aux activités ({{ inscriptions.length }})
            </b-checkbox>
            <b-checkbox id="showEntities" v-model="showEntities" v-if="changes.length">
              Fiches famille ({{ changes.length }})
            </b-checkbox>
          </b-col>
        </b-row>
      </div>
      <table class="table table-striped table-bordered">
        <tr v-if="items.length === 0">
          <td class="empty-text">Aucun élément à confirmer</td>
        </tr>
        <tr v-for="item of items" :key="item.type + item.elt.id">
          <td>{{ item.createdOn | dateToString('ddd ll HH:mm') }}</td>
          <td v-if="item.type === 'inscription'">
            <a href="" @click.prevent="showEntitySidebar(item.elt.entity)">
              {{ item.elt.individual.firstAndLastName() }}
            </a>
            <router-link :to="getEntityLink(item.elt, 'activities')">
              <i class="fa fa-external-link"></i>
            </router-link>
          </td>
          <td v-if="item.type === 'change'">
            <a href="" @click.prevent="showEntitySidebar(item.elt.entity)">
              {{ item.elt.entity.name }}
            </a>
            <router-link :to="getEntityLink(item.elt, '')">
              <i class="fa fa-external-link"></i>
            </router-link>
          </td>
          <td v-if="item.type === 'inscription'">
            {{ item.elt.activity.name }}
            <div v-for="seat of item.elt.seats" :key="seat.index">
              {{ seat.name }}: {{ seat.seats }} * {{ seat.price | currency }}
            </div>
          </td>
          <td v-if="item.type === 'inscription'">
            <div v-if="item.elt.sale">{{ item.elt.sale.price | currency }}</div>
            <span class="badge badge-danger" v-if="item.elt.waiting">En attente</span>
          </td>
          <td v-if="item.type === 'change'" colspan="2">
            <div v-if="item.elt.changes.length"></div>
              <table class="table table-full table-sub-data table-no-strip">
                <tr>
                  <th>Donnée</th>
                  <th>Ancienne</th>
                  <th>MAJ</th>
                </tr>
                <tr v-for="change of item.elt.changes" :key="change.index">
                  <td>{{ change.field }}</td>
                  <td>{{ change.prevValue }}</td>
                  <td>{{ change.updatedValue }}</td>
                </tr>
              </table>
          </td>
          <td v-if="item.type === 'inscription'">
            <invoice-badge :invoice="item.elt.sale.invoice" v-if="item.elt.sale.price"></invoice-badge>
          </td>
          <td v-if="item.type === 'change'">
            <div v-for="certif of item.elt.certificates" :key="certif.id">
              <a href @click.prevent="onViewDocument(certif)">
                <i class="fa fa-download"></i> {{ certif.fileName }}
              </a>
            </div>
            <div v-html="item.elt.errors.join('<br />')"></div>
          </td>
          <td>
            <div>
              <b-checkbox
                :id="'accept' + item.type + item.elt.id"
                :checked="isAccepted(item)"
                @change="onAccept(item, $event)"
              >
                Accepter
              </b-checkbox>
            </div>
            <div>
              <b-checkbox
                :id="'refuse' + item.type + item.elt.id"
                :checked="isRefused(item)"
                @change="onRefuse(item, $event)"
                v-if="hasRefused(item)"
              >
                {{ refusedLabel(item) }}
              </b-checkbox></div>
          </td>
        </tr>
      </table>
    </div>
    <confirm-modal
      name="confirm-portal-data"
      :title="confirmTitle"
      :text="confirmText"
      @confirmed="doConfirm()"
    >
    </confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import { makeActivityInscription } from '@/types/activities'
import { makeEntityPublicChange } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import { compareDates } from '@/utils/sorting'

export default {
  name: 'confirm-portal-data',
  props: {
  },
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    ConfirmModal,
    InvoiceBadge,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'confirm-portal-data',
      inscriptions: [],
      accepted: [],
      refused: [],
      changes: [],
      acceptedChanges: [],
      refusedChanges: [],
      entityFilter: '',
      showEntities: true,
      showActivities: true,
    }
  },
  async created() {
    await this.loadData()
  },
  watch: {
  },
  computed: {
    title() {
      return 'Vérification des données saisies par les familles '
    },
    canView() {
      return this.hasOnePerm(
        ['activities.view_coreactivityinscription', 'cms.view_activityarticle']
      )
    },
    confirmTitle() {
      return 'Confirmer les données issues du portail'
    },
    confirmText() {
      const texts = []
      if (this.accepted.length) {
        if (this.accepted.length > 1) {
          texts.push('' + this.accepted.length + ' inscriptions seront confirmées')
        } else {
          texts.push('Une inscription sera confirmée')
        }
      }
      if (this.refused.length) {
        if (this.refused.length > 1) {
          texts.push('' + this.refused.length + ' inscriptions seront refusées')
        } else {
          texts.push('Une inscription sera refusée')
        }
      }
      if (this.acceptedChanges.length) {
        if (this.acceptedChanges.length > 1) {
          texts.push('' + this.acceptedChanges.length + ' modifications seront confirmées')
        } else {
          texts.push('Une modification sera confirmée')
        }
      }
      if (this.refusedChanges.length) {
        if (this.refusedChanges.length > 1) {
          texts.push('' + this.refusedChanges.length + ' modification seront refusées. Les QF seront rétablis')
        } else {
          texts.push('Une inscription sera refusée. Le QF sera rétabli')
        }
      }
      return texts.join('\n\n')
    },
    items() {
      let items = []
      if (this.showActivities) {
        items = this.inscriptions.map(
          ins => {
            return {
              createdOn: ins.createdOn,
              elt: ins,
              type: 'inscription',
            }
          }
        )
      }
      if (this.showEntities) {
        items = items.concat(
          this.changes.map(
            change => {
              return {
                createdOn: change.createdOn,
                elt: change,
                type: 'change',
              }
            }
          )
        )
      }
      if (this.entityFilter) {
        items = items.filter(
          item => item.elt.entity.name.toLowerCase().indexOf(this.entityFilter.toLowerCase()) >= 0
        )
      }
      return items.sort(
        (elt1, elt2) => compareDates(elt1.createdOn, elt2.createdOn)
      )
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadFamilyChanges() {
      if (this.hasPerm('cms.view_activityarticle')) {
        try {
          const url = '/api/accounts/entity-public-updates/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.changes = resp.data.map(elt => makeEntityPublicChange(elt))
        } catch (err) {
          // silent
          // await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadInscriptions() {
      if (this.hasPerm('activities.view_coreactivityinscription')) {
        try {
          const url = '/api/activities/unconfirmed-inscriptions/?category=' + this.category
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.inscriptions = resp.data.map(elt => makeActivityInscription(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadInscriptions()
      await this.loadFamilyChanges()
      this.endLoading(this.loadingName)
    },
    getEntityLink(elt, tab) {
      const params = { entityId: '' + elt.entity.id, }
      const query = {
        // individual: '' + inscription.individual.id,
        tab: tab,
      }
      return { name: 'families-detail', params: params, query: query, }
    },
    async reload() {
      await this.loadData()
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'inscriptions-a-confirmer'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const hasChecked = (
        this.accepted.length || this.refused.length || this.acceptedChanges.length ||
        this.refusedChanges.length
      )
      return [
        {
          id: 4,
          label: 'Rafraîchir',
          callback: this.reload,
          icon: 'fa fa-refresh',
          cssClass: 'btn-secondary',
        },
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: 'btn-secondary',
        },
        {
          id: 3,
          label: 'Confirmer',
          callback: this.confirm,
          icon: 'fa fa-check',
          cssClass: hasChecked ? 'btn-primary' : 'btn-primary disabled',
        }
      ]
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'inscriptions-a-confirmer'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    confirm() {
      this.$bvModal.show('bv-confirm-modal:confirm-portal-data')
    },
    async doConfirm() {
      this.startLoading(this.loadingName)
      await this.doConfirmInscriptions()
      await this.doConfirmChanges()
      this.endLoading(this.loadingName)
    },
    async doConfirmInscriptions() {
      if (this.accepted.length || this.refused.length) {
        try {
          const url = '/api/activities/unconfirmed-inscriptions/'
          let backendApi = new BackendApi('post', url)
          const data = {
            'accepted': this.accepted,
            'refused': this.refused,
          }
          let resp = await backendApi.callApi(data)
          this.inscriptions = resp.data.map(elt => makeActivityInscription(elt))
          this.accepted = []
          this.refused = []
          this.addSuccess('Les inscriptions ont été confirmées')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async doConfirmChanges() {
      if (this.acceptedChanges.length || this.refusedChanges.length) {
        try {
          const url = '/api/accounts/entity-public-updates/'
          let backendApi = new BackendApi('post', url)
          const data = {
            'accepted': this.acceptedChanges,
            'refused': this.refusedChanges,
          }
          let resp = await backendApi.callApi(data)
          this.changes = resp.data.updates.map(elt => makeEntityPublicChange(elt))
          for (const message of resp.data.messages) {
            if (message.success) {
              await this.addSuccess(message.text)
            } else {
              await this.addError(message.text)
            }
          }
          this.acceptedChanges = []
          this.refusedChanges = []
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getLinkToActivity(activity, youth) {
      if (youth) {
        return { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
    },
    isAccepted(item) {
      if (item.type === 'inscription') {
        return this.accepted.indexOf(item.elt.id) >= 0
      }
      return this.acceptedChanges.indexOf(item.elt.id) >= 0
    },
    isRefused(item) {
      if (item.type === 'inscription') {
        return this.refused.indexOf(item.elt.id) >= 0
      }
      return this.refusedChanges.indexOf(item.elt.id) >= 0
    },
    hasRefused(item) {
      if (item.type === 'inscription') {
        return true
      }
      return item.elt.changes.filter(elt => elt.field === 'QF').length > 0
    },
    refusedLabel(item) {
      if (item.type === 'inscription') {
        return 'Refuser'
      }
      return 'Restaurer le QF'
    },
    onAccept(item, value) {
      if (item.type === 'inscription') {
        const ins = item.elt
        if (value) {
          this.accepted.push(ins.id)
          const index = this.refused.indexOf(ins.id)
          if (index >= 0) {
            this.refused.splice(index, 1)
          }
        } else {
          const index = this.accepted.indexOf(ins.id)
          if (index >= 0) {
            this.accepted.splice(index, 1)
          }
        }
      } else {
        const ins = item.elt
        if (value) {
          this.acceptedChanges.push(ins.id)
          const index = this.refusedChanges.indexOf(ins.id)
          if (index >= 0) {
            this.refusedChanges.splice(index, 1)
          }
        } else {
          const index = this.acceptedChanges.indexOf(ins.id)
          if (index >= 0) {
            this.acceptedChanges.splice(index, 1)
          }
        }
      }
    },
    onRefuse(item, value) {
      if (item.type === 'inscription') {
        const ins = item.elt
        if (value) {
          this.refused.push(ins.id)
          const index = this.accepted.indexOf(ins.id)
          if (index >= 0) {
            this.accepted.splice(index, 1)
          }
        } else {
          const index = this.refused.indexOf(ins.id)
          if (index >= 0) {
            this.refused.splice(index, 1)
          }
        }
      } else {
        const ins = item.elt
        if (value) {
          this.refusedChanges.push(ins.id)
          const index = this.acceptedChanges.indexOf(ins.id)
          if (index >= 0) {
            this.acceptedChanges.splice(index, 1)
          }
        } else {
          const index = this.refusedChanges.indexOf(ins.id)
          if (index >= 0) {
            this.refusedChanges.splice(index, 1)
          }
        }
      }
    },
    async onViewDocument(document) {
      const docUrl = '/accounts/download-activity-certificate-document/<key>/'
      const docSlug = '' + document.id
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped lang="less">
.school-years {
  background: #eee;
}
.school-years .badge {
  display: inline-block;
  margin-right: 2px;
}
.table-sub-data tr td,
.table-sub-data tr th {
  font-size: 0.7em;
  background: transparent !important;
}
</style>
